'use client';

import { Dispatch, FC, PropsWithChildren, ReactNode, Reducer, useMemo, useReducer } from 'react';

import { Address } from 'viem';

import { MissingFieldsEntry } from '@api';

import { ModalCloseButton, ModalContent, ModalHeading } from '@uikit/components/Modal';
import ModalDescription from '@uikit/components/Modal/ModalDescription';

import { RequestSignProps } from '@shared/helpers/web3/hooks/useWeb3';

import { createSafeContext, useSafeContext } from '@utilities/context';

interface GlobalStateInterface {
  missingFieldsEntry: MissingFieldsEntry | null;
  wantsInMagicBoost: boolean;
  isFirstSiteRender: boolean;
  connectAndSignConfirmCallback: {
    confirmCallback: (...args: any) => Promise<void>;
    setIsLoading: (value: boolean) => void;
    confirmStepTitle: string;
    connectStepTitle: string;
    confirmButtonText: string;
    signProps: Omit<RequestSignProps, 'skipConnectStep' | 'nonce'>;
  };
  banxa: {
    walletAddress?: Address;
    productName?: string;
  };
  customContent: {
    content: ReactNode;
  };
  // Add new fields here
}

type GlobalStateAction = {
  type: keyof GlobalStateInterface;
  value: GlobalStateInterface[keyof GlobalStateInterface];
};

const initialGlobalState: GlobalStateInterface = {
  missingFieldsEntry: { success: false, description: {} },
  wantsInMagicBoost: false,
  isFirstSiteRender: true,
  connectAndSignConfirmCallback: {
    confirmCallback: () => Promise.resolve(),
    setIsLoading: () => {},
    confirmStepTitle: 'Please confirm the action by a Signature',
    connectStepTitle: 'Please connect your wallet',
    confirmButtonText: 'Confirm with Sign',
    signProps: { walletType: 'EVM', message: 'Message is not defined' },
  },
  banxa: {
    productName: 'Magic Store',
  },
  customContent: {
    content: (
      <ModalContent className="flex flex-col items-center p-6 sm:px-12  w-full max-w-[472px] text-center">
        <ModalCloseButton className="!mb-4" />
        <ModalHeading className="mb-4">Nothing here yet ;)</ModalHeading>

        <ModalDescription className="font-light text-sm text-neutral-500 mb-4">
          Please provide the content to display here.
        </ModalDescription>
      </ModalContent>
    ),
  },
};

const globalStateReducer: Reducer<GlobalStateInterface, GlobalStateAction> = (
  state,
  { type, value },
) => {
  if (Object.keys(initialGlobalState).includes(type)) {
    return {
      ...state,
      [type]: value,
    };
  }

  throw new Error('Unknown action: ' + type);
};

interface GlobalStateContextInterface {
  state: GlobalStateInterface;
  setState: Dispatch<GlobalStateAction>;
}

const GlobalStateContext = createSafeContext<GlobalStateContextInterface>();

export const GlobalStateProvider: FC<PropsWithChildren> = ({ children }) => {
  const [state, setState] = useReducer(globalStateReducer, initialGlobalState);

  return (
    <GlobalStateContext.Provider value={{ state, setState }}>
      {children}
    </GlobalStateContext.Provider>
  );
};

const useGlobalState = <TName extends keyof GlobalStateInterface>(fieldName: TName) => {
  const context = useSafeContext(GlobalStateContext);

  return useMemo(
    () => ({
      value: context.state[fieldName] as GlobalStateInterface[TName],
      setValue: (value: GlobalStateInterface[TName]) => {
        context.setState({ type: fieldName, value });
      },
    }),
    [context, fieldName],
  );
};

export default useGlobalState;
